import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import { fetchTrackingOrder } from 'services/orderService';
import CircularProgress from 'components/EnhancedCircularProgress';
import ButtonCircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import { Step, Stepper, StepLabel, StepContent, StepConnector } from '@mui/material';
import Chip from '@mui/material/Chip';
import AmountWithCurrency from 'components/AmountWithCurrency';
import RaisedButton from '@mui/material/Button';
import {
  CASH_COLLECTION_TYPE_COLLECT_ON_DELIVERY,
  CASH_COLLECTION_TYPE_COLLECT_ON_PICKUP,
  ORDER_TYPE_ONE_WAY,
  ORDER_TYPE_EXPRESS_SINGLE,
  ORDER_TYPE_ROUND,
  ORDER_TYPE_EXPRESS_ROUND,
  ORDER_TYPE_BULK,
  ORDER_TYPE_ROUND_DELIVERY,
  TASK_TYPE_DELIVER,
  CUSTOMER_TYPE_OFFLINE_BUSINESS,
  CUSTOMER_TYPE_CUSTOMER,
} from 'constants/Types';
import {
  ORDER_STATUS_PENDING,
  ORDER_STATUS_SUCCESS,
  ORDER_STATUS_CLOSED_FAILED,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_PENDING_CANCELLATION,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_CONFIRMED,
  TASK_STATUS_COMPLETE,
  TASK_STATUS_STARTED,
} from 'constants/Statuses';
import WarningIcon from '@mui/icons-material/Warning';
import { red } from '@mui/material/colors';
import classnames from 'classnames';
import TextField from 'components/EnhancedTextField';
import FlatButton from '@mui/material/Button';
import Button from '@mui/material/Button';
import AddReview from 'components/AddReview';
import * as modalsActions from "stores/actions/modals";
import { styles } from './styles';
import { withStyles } from '@mui/styles';
import { submitReview } from 'services/ReviewService';
import MapPins from 'components/EnhancedMapPins';
import Echo from 'laravel-echo';
import APPCONFIG from 'constants/Config';
import Pusher from 'pusher-js';
import { fetchTypeList } from 'services/dataService';
import { dateFormatter } from 'utils';
import moment from 'moment';
import { push } from 'connected-react-router';
import {ORDER_SOURCE_MARKETPLACE} from 'constants/Data';
const step_status_failed = 1;
const step_status_completed = 2;
const step_status_processing = 3;
const step_status_pending = 4;

const break_mobile_point = 880;

const CustomizedStep = (props) => {
  const { status, step_label, step_details, windowWidth, order_status_code } =
    props;

  var step_component = [];
  switch (status) {
    case step_status_failed:
      step_component = [
        <StepLabel
          key={1}
          icon={<WarningIcon color={red[500]} />}
          style={{ color: red[500] }}
        >
          <p style={{ marginBottom: 0, color: red[500] }}>{step_label}</p>
        </StepLabel>,
      ];
      break;
    case step_status_completed:
      step_component = [
        <StepLabel
          key={2}
          icon={
            <span className="material-icons" style={{ color: '#228B22' }}>
              check_circle
            </span>
          }
        >
          <p style={{ marginBottom: 0 }}>{step_label}</p>
        </StepLabel>,
      ];
      break;
    case step_status_processing:
      step_component = [
        <StepLabel
          key={3}
          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
          icon={
            <span className="material-icons" style={{ color: '#ffc107' }}>
              adjust
            </span>
          }
        >
          <p style={{ marginBottom: 0, color: 'rgba(0, 0, 0, 0.87)' }}>{step_label}</p>
        </StepLabel>,
      ];
      break;
    case step_status_pending:
      step_component = [
        <StepLabel
          key={4}
          style={{ color: 'rgb(158, 158, 158)' }}
          icon={<span className="material-icons">adjust</span>}
        >
          <p style={{ marginBottom: 0, color: "rgb(158, 158, 158)" }}>{step_label}</p>
        </StepLabel>,
      ];
      break;
  }

  return (
    <Step active>
      {step_component}
      <StepContent icon="">
        <p
          style={
            status === step_status_pending
              ? { color: 'rgb(158, 158, 158)' }
              : {}
          }
        >
          {step_details}
        </p>
      </StepContent>
    </Step>
  );
};

const ConfirmedDetails = (props) => {
  const { order , show_delivery_message } = props;
  return order && order.orderDetails && order.orderDetails.customer ? (
    <span>
      {order.waybill && (
        <span>
          <b>Waybill: </b>
          {order.waybill}
          <br />
        </span>
      )}
      {order.orderDetails.customer.name !==
        order.orderDetails.senderable.name && (
        <span>
          <b>Customer: </b>
          {order.orderDetails.customer.name}
          <br />
        </span>
      )}
      <span>
        <b>From: </b>
        {order.orderDetails.senderable.name}
        <br />
      </span>
      <span>
        {show_delivery_message &&
          (process.env.REACT_APP_COUNTRY == "JO"
            ? "Wakilni Team will reach out to you to coordinate your order delivery"
            : "Deliveries typically take 1 - 4 working days.")}
      </span>
      {order.orderDetails.cash_collection_type_id ===
        CASH_COLLECTION_TYPE_COLLECT_ON_PICKUP && (
        <span>
          <b>Collection amount: </b>
          <AmountWithCurrency
            amount={order.orderDetails.collection_amount}
            currency_id={order.orderDetails.collection_currency_id}
          />
          <br />
        </span>
      )}
    </span>
  ) : (
    <span />
  );
};

const PickedUpDetails = (props) => {
  const { order } = props;

  let is_pickup_task_driver_assigned = null;
  let driver_name = null;
  let driver_phone = null;
  let order_type = order.orderDetails?.type;
  let is_order_bulk_deliver = order_type == ORDER_TYPE_BULK && order.parent_id;

  if (!is_order_bulk_deliver && order.pickup_tasks?.length > 0) {
    is_pickup_task_driver_assigned =
      order.pickup_tasks &&
      order.pickup_tasks[order.pickup_tasks?.length - 1].driver != null;
  }

  if (is_pickup_task_driver_assigned) {
    driver_name = order.pickup_tasks[order.pickup_tasks?.length - 1].driver.name;
    driver_phone =
      order.pickup_tasks[order.pickup_tasks?.length - 1].driver.phone_number;
  }

  if (order_type == ORDER_TYPE_BULK && !order.parent_id) {
    return order.worker &&
      order.worker.name &&
      order.pickup_tasks[order.pickup_tasks?.length - 1].status ==
        TASK_STATUS_STARTED ? (
      <span>
        <span>
          <b>Driver name: </b>
          {order.worker.name}
          <br />
        </span>
        <span>
          <b>Driver mobile: </b>
          {order.worker.phone_number}
          <br />
        </span>
      </span>
    ) : (
      <span />
    );
  } else {
    return is_pickup_task_driver_assigned &&
      order.pickup_tasks[order.pickup_tasks?.length - 1].status ==
        TASK_STATUS_STARTED ? (
      <span>
        <span>
          <b>Driver name: </b>
          {driver_name}
          <br />
        </span>
        <span>
          <b>Driver mobile: </b>
          {driver_phone}
          <br />
        </span>
      </span>
    ) : (
      <span />
    );
  }
};

const OnTheWayDetails = (props) => {
  const { order } = props;

  let round_delivery_tasks = [];
  let driver_name = null;
  let driver_phone = null;
  let order_type = order.orderDetails?.type;
  let is_deliver_task_driver_assigned = null;
  let is_order_type_round =
    order_type == ORDER_TYPE_ROUND || order_type == ORDER_TYPE_EXPRESS_ROUND;

  if (is_order_type_round) {
    round_delivery_tasks = order.deliver_tasks.filter(
      (task) => task.sequence == 2,
    );
  }
  if (order.deliver_tasks && order.tasks?.length > 0) {
    is_deliver_task_driver_assigned =
      order.deliver_tasks &&
      ((!is_order_type_round &&
        order.deliver_tasks[order.deliver_tasks?.length - 1].driver != null) ||
        (is_order_type_round &&
          round_delivery_tasks[round_delivery_tasks?.length - 1].driver !=
            null));
  }

  if (is_deliver_task_driver_assigned) {
    if (is_order_type_round) {
      driver_name =
        round_delivery_tasks[round_delivery_tasks?.length - 1].driver.name;
      driver_phone =
        round_delivery_tasks[round_delivery_tasks?.length - 1].driver
          .phone_number;
    } else {
      driver_name =
        order.deliver_tasks[order.deliver_tasks?.length - 1].driver.name;
      driver_phone =
        order.deliver_tasks[order.deliver_tasks?.length - 1].driver.phone_number;
    }
  }

  let is_task_started = null;

  if (order.tasks?.length > 0) {
    is_task_started =
      (is_order_type_round &&
        round_delivery_tasks[round_delivery_tasks?.length - 1].status ==
          TASK_STATUS_STARTED) ||
      (!is_order_type_round &&
        order.deliver_tasks[order.deliver_tasks?.length - 1].status ==
          TASK_STATUS_STARTED);
  }
  
  if (order.self_pickup){
    return (
      <span>
        <b>At: </b>
        {order.self_pickup_office.name}
      </span>
    )
  }

  if (order_type == ORDER_TYPE_BULK) {
    return order.worker && order.worker.name && is_task_started ? (
      <span>
        <span>
          <b>Driver name: </b>
          {order.worker.name}
          <br />
        </span>
        <span>
          <b>Driver mobile: </b>
          {order.worker.phone_number}
          <br />
        </span>
      </span>
    ) : (
      <span />
    );
  } else {
    return is_deliver_task_driver_assigned && is_task_started ? (
      <span>
        <span>
          <b>Driver name: </b>
          {driver_name}
          <br />
        </span>
        <span>
          <b>Driver mobile: </b>
          {driver_phone}
          <br />
        </span>
      </span>
    ) : (
      <span />
    );
  }
};

const DeliveredDetails = (props) => {
  const { order } = props;
  return order && order.orderDetails && order.orderDetails.receiverable ? (
    <span>
      <span>
        <b>To: </b>
        {order.orderDetails.receiverable.name}
        <br />
      </span>
      {!order.self_pickup && 
        <span>
          <b>Destination location: </b>
          {order.orderDetails.receiver_location?.type_label},{' '}
          {order.orderDetails.receiver_location.area.name}
          <br />
        </span>
      }
      {order.orderDetails.cash_collection_type_id ===
        CASH_COLLECTION_TYPE_COLLECT_ON_DELIVERY && (
        <span>
          <b>Collection amount: </b>
          <AmountWithCurrency
            amount={order.orderDetails.collection_amount}
            currency_id={order.orderDetails.collection_currency_id}
            breakLine={false}
          />
          <br />
        </span>
      )}
      {order.completed_on && moment(order.completed_on).isValid() && (
        <span>
          { order.self_pickup ?
            <b>Picked Up At: </b>
            : <b>Delivered date: </b>
          }
          {dateFormatter(order.completed_on)}
          <br />
        </span>
      )}
    </span>
  ) : (
    <span />
  );
};

const ReturnedDetails = (props) => {
  const { order } = props;
  let order_type = null;
  let round_return_tasks = [];
  let driver_name = null;
  let driver_phone = null;
  let is_return_task_started = null;
  let is_order_type_round =
    order_type == ORDER_TYPE_ROUND || order_type == ORDER_TYPE_EXPRESS_ROUND;

  if (order && order.orderDetails) {
    order_type = order.orderDetails?.type;
    if (is_order_type_round && order.deliver_tasks) {
      round_return_tasks = order.deliver_tasks.filter(
        (task) => task.sequence == 3,
      );
      if (round_return_tasks[round_return_tasks?.length - 1].driver) {
        driver_name =
          round_return_tasks[round_return_tasks?.length - 1].driver.name;
        driver_phone =
          round_return_tasks[round_return_tasks?.length - 1].driver.phone_number;
        is_return_task_started =
          round_return_tasks[round_return_tasks?.length - 1].status ==
          TASK_STATUS_STARTED;
      }
    }
  }

  return order && order.orderDetails && order.orderDetails.customer ? (
    <span>
      {driver_name && driver_phone && is_return_task_started && (
        <span>
          <span>
            <b>Driver name: </b>
            {driver_name}
            <br />
          </span>
          <span>
            <b>Driver mobile: </b>
            {driver_phone}
            <br />
          </span>
        </span>
      )}
      <span>
        <b>To: </b>
        {order.orderDetails.senderable.name}
      </span>
    </span>
  ) : (
    <span />
  );
};

class RateComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      feedback_msg: '',
      rate: null,
      shop_feedback_msg: '',
      shop_rate: null,
      submitted_successfully: false,
      show_rating:
        this.props.show_rating &&
        this.props.order.status == statuses.ORDER_STATUS_SUCCESS,
      rateErrorText: '',
      shopRateErrorText: '',


    };
    
  }

  handleTextChange = (event) => {
    const value = event.target.value;
    this.setState({ feedback_msg: value });
  };

  handleShopTextChange = (event) => {
    const value = event.target.value;
    this.setState({ shop_feedback_msg: value });
  };

  changeRate = (rate) => {
    this.setState({
      rate: this.state.rate == rate ? null : rate,
      rateErrorText:""
    });
  };

  changeShopRate = (rate) => {
    this.setState({
      shop_rate: this.state.shop_rate == rate ? null : rate,
      shopRateErrorText:""

    });
  };

  handleSubmit = () => {
    if (!this.state.rate && !this.state.shop_rate) {
    
      this.setState((state) => ({rateErrorText:'Please rate your experience'}));
      return;
    }


    var info = {
      input: {
        feedback_message: this.state.feedback_msg,
        shop_feedback_message: this.state.shop_feedback_msg,
        rate: this.state.rate,
        shop_rate: this.state.shop_rate,
        order_id: this.props.order?.id,
        customer_id: this.props.order.orderDetails.customer?.id,
        recipient_id: this.props.order.orderDetails.receiverable?.id,
        viewed: false,
      },
    };

    this.setState({ ...this.state, isLoading: true });

    this.props
      .dispatch(submitReview(info))
      .then((response) => {
        const responseError = {
          isError: false,
          code: '',
          text: '',
        };
        this.setState((state) => ({
          show_rating: false,
          responseError,
          submitted_successfully: true,
          isLoading: false,
        }));
        if (this.state.rate===1){ 
          this.props.dispatch(modalsActions.addReview(true, {}));
        }
      })
      .catch(({ error, statusCode }) => {
        const responseError = {
          isError: true,
          code: statusCode,
          text: error,
        };
        this.setState((state) => ({ responseError }));
      });
  };

  render() {
    const { classes, order } = this.props;
    const { submitted_successfully, isLoading, rate, shop_rate, shopRateErrorText, rateErrorText } = this.state;
    const buttonStyle = (selectedRate, currentRate) => ({
      backgroundColor: '#F8F8F8',
      borderRadius: '10px',
      border: `2px solid ${selectedRate === currentRate ? '#C1C1C1' : '#F4F5F9'}`,
      height: '10%',
      width: '5%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    });
    const windowWidth = window.innerWidth;
    const isMobile = windowWidth < break_mobile_point;

    const starImages = (rating, changeRating) => (
      <div className={'col-sx-12'} style={{ display: 'flex', gap: '2%', justifyContent:isMobile?'center':'', marginTop: '10px' }}>
        {[1, 2, 3, 4, 5].map((num) => (
          <Button key={num} onClick={() => changeRating(num)} style={{minWidth:'50px'}}>
            <img
              src={rating >= num ? '/assets/images/checked-star.svg' : '/assets/images/empty-star.svg'}
              alt="star"
              style={{ height: '30px' }}
            />
          </Button>
        ))}
      </div>
    );
    return (
      <div className={'col-md-12'}>
        {submitted_successfully ? (
          <div className="row" style={{ marginTop: '10%', justifyContent:'center' }}>
            <div className="col-md-12">
              <h3 style={{ textAlign: 'center' }}>
                <span
                  className="material-icons"
                  style={{ color: '#228B22', fontSize: '90px' }}
                >
                  check_circle
                </span>
              </h3>
              <h3 style={{ textAlign: 'center' }}>Thank you</h3>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: '2%' }}>
            <h6 style={{ fontWeight: '400', fontSize:'1rem' }}>How was the Wakilni delivery experience?</h6>
            {rateErrorText && (
              <div style={{ lineHeight: "5", color: "red" }}>
                {rateErrorText}
              </div>
            )}
            <div style={{ display: 'flex', gap: '2%', flexWrap: 'wrap', justifyContent: isMobile ? 'center':'' }}>
              <Button onClick={() => this.changeRate(1)} style={buttonStyle(rate, 1)}>
                <img src="/assets/images/rate-1.svg" alt="Great" style={{ height: '50px' }} />
              </Button>
              <Button onClick={() => this.changeRate(2)} style={buttonStyle(rate, 2)}>
                <img src="/assets/images/rate-2.svg" alt="Good" style={{ height: '50px' }} />
              </Button>
              <Button onClick={() => this.changeRate(3)} style={buttonStyle(rate, 3)}>
                <img src="/assets/images/rate-3.svg" alt="Ok" style={{ height: '50px' }} />
              </Button>
              <Button onClick={() => this.changeRate(4)} style={buttonStyle(rate, 4)}>
                <img src="/assets/images/rate-4.svg" alt="Bad" style={{ height: '50px' }} />
              </Button>
            </div>
            <TextField
              name="feedback_msg"
              floatingLabelText="Share Your Feedback"
              value={this.state.feedback_msg}
              onChange={this.handleTextChange}
              fullWidth
            />
            <h6 style={{ fontWeight: '400', fontSize:'1rem', marginTop: '10px', lineHeight: "1.5"}}> {order.source_id === ORDER_SOURCE_MARKETPLACE ? `How was your experience with ${order.orderDetails.senderable.name}?` : 'Leave a review for the shop & purchased product(s)'}</h6>
            <div className='text-center'>
              {shopRateErrorText && (
                <span style={{ lineHeight: "5", color: "red" }}>
                  {shopRateErrorText}
                </span>
              )}
            {starImages(shop_rate, this.changeShopRate)}
             
            </div>
            <TextField
              name="shop_feedback_msg"
              floatingLabelText="Share Your Feedback"
              value={this.state.shop_feedback_msg}
              onChange={this.handleShopTextChange}
              fullWidth
            />
            <div className='row'>

            <div className='col-md-4'>
              {isLoading ? (
                <ButtonCircularProgress />
              ) : (
                <RaisedButton variant="contained"
                label="Submit"
                color="primary"
                onClick={this.handleSubmit}
                style={{width: '100%', backgroundColor:'#ffc107', 'opacity':!rate && !shop_rate ? 0.35 : 1, 'paddingTop': '3%','paddingBottom': '3%'}}
                disabled={!rate && !shop_rate}
                className={classes.raised_buttons}>
                  Submit Review
                </RaisedButton>
              )}
            </div>
            </div>
            {order.source_id === ORDER_SOURCE_MARKETPLACE && (
              <div  className="text-center text-sm-left">
                {" "}
                <div
                  style={{
                    fontweight: "500",
                    marginTop: "10px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>
                    Want to review the products? Visit our app!{" "}
                  </span>
                </div>
                <div style={{ fontSize: "0.9rem"}}>
                  If you don’t have it yet,{" "}
                  <span>
                    {" "}
                    <a
                      target="_blank"
                      style={{ color: "#ffc107", textDecoration: "none" }}
                      href={"https://apps.wakilni.com"}
                    >
                      download it here
                    </a>
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

class TrackingMap extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      route: [],
    };
  }


  render() {
    const { classes, order, coordinates } = this.props;
    const { route } = this.state;
    let drivers_map_location = order.drivers_location
      ? coordinates
        ? {
            id: order.drivers_location?.id,
            name: order.drivers_location.name,
            longitude: coordinates.lng,
            latitude: coordinates.lat,
            now_driving: order.drivers_location.now_driving,
          }
        : order.drivers_location
      : null;

    return (
      <div className={'col-md-12'}>
        <div className="pt-4 h-100">
          <MapPins height="100%" width="100%" driver={drivers_map_location} />
        </div>
      </div>
    );
  }
}

class TrackingOrder extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: true,
      stepIndex: 0,
      windowWidth: window.innerWidth,
      updated_coordinate: null,
      show_rating:
        props.show_rating && props.order.status == ORDER_STATUS_SUCCESS,
    };
  }

  componentWillUpdate(nextProps) {
    if (nextProps.match.params.number !== this.props.match.params.number) {
      window.Echo.disconnect();
      this.loadOrder(nextProps.match.params.number);
    }
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    this.loadTypeList();
    window.addEventListener('resize', this.handleResize);
    var code = this.props.match.params.code;
    if (code) {
      this.loadOrder(this.props.match.params.number, atob(code));
    } else {
      this.loadOrder(this.props.match.params.number);
    }
  }

  loadTypeList() {
    this.props
      .dispatch(fetchTypeList(true))
      .then((response) => {})
      .catch(({ error, statusCode }) => {});
  }

  componentWillUnMount() {
    window.removeEventListener('resize', this.handleResize);
    window.Echo.disconnect();
  }

  loadActiveDriver (data) {
    let tasks= data.t;
    let task_started;
    if (tasks) {
      task_started = tasks.find(
        (task) => task.status == TASK_STATUS_STARTED
      );
    }
    if (task_started){
      return task_started.driver_id;
    }
    return undefined;
  }

  setUpPusher(driver) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      authEndpoint: APPCONFIG.socket_api,
      key: APPCONFIG.pusher_key,
      cluster: APPCONFIG.pusher_cluster,
      forceTLS: true,
      auth: {
        headers: {
          'X-CSRF-Token': 'SOME_CSRF_TOKEN',
        },
      },
    });
    window.Echo.channel('track-order-' + driver).listen(
      '.track',
      (e) => {
        this.setState({
          updated_coordinate: e.coordinates,
        });
      },
    )
    // Disconnect after 1 hour
    setTimeout(() => {
      window.Echo.disconnect();
    }, 3600000);
  }

  loadOrder(number, code = null) {
    this.setState((state) => ({ isLoading: true }));

    this.props
      .dispatch(fetchTrackingOrder(number, code))
      .then((data) => {
        let stepIndex = 1;

        if (this.props.order.status === ORDER_STATUS_PENDING) {
          stepIndex = 0;
        }

        if (this.props.order.status === ORDER_STATUS_PROCESSING) {
          stepIndex = 2;
        }

        if (this.props.order.status === ORDER_STATUS_SUCCESS) {
          stepIndex =
            this.props.order.orderDetails?.type != ORDER_TYPE_ROUND ||
            this.props.order.orderDetails?.type != ORDER_TYPE_EXPRESS_ROUND
              ? 3
              : 4;
        }
        const activeDriver = this.loadActiveDriver(data.data)
        
        this.setState((state) => ({
          isLoading: false,
          show_rating: data.meta.can_rate,
          stepIndex: stepIndex,
        }));
        if (activeDriver) {
          this.setUpPusher(activeDriver);
        }
      })
      .catch(({ error, statusCode }) => {
        this.setState((state) => ({ isLoading: false }));
        if (statusCode == '404') {
          this.props.dispatch(push(`/404`));
        }
      });
  }

  render() {
    const { order, classes, add_review } = this.props;
    const { stepIndex, windowWidth } = this.state;

    let order_type = null;
    let first_pickup_task = null;
    let delivery_task = null;

    let is_order_failure = null;
    let is_order_success = null;
    let is_order_pending = null;
    let is_order_declined = null;
    let is_pickup_task_started = null;
    let is_deliver_task_started = null;
    let is_return_task_started = null;
    let is_any_task_started = null;

    if (order && order.orderDetails) {
      var sms_msg = `Hi, this is ${order.orderDetails.receiverable.name}. I want to double check the status of my order from ${order.orderDetails.customer.name} #${order?.id}.`;
      order_type = order.orderDetails?.type;
      is_order_failure =
        order.status === ORDER_STATUS_DECLINED ||
        order.status === ORDER_STATUS_CANCELED ||
        order.status === ORDER_STATUS_CLOSED_FAILED ||
        order.status === ORDER_STATUS_FAILED ||
        order.status === ORDER_STATUS_PENDING_CANCELLATION;
      is_order_success = order.status === ORDER_STATUS_SUCCESS;
      is_order_pending = order.status === ORDER_STATUS_PENDING;
      is_order_declined = order.status === ORDER_STATUS_DECLINED;

      if (order.tasks) {
        if (
          order_type == ORDER_TYPE_ROUND ||
          order_type == ORDER_TYPE_EXPRESS_ROUND ||
          order_type == ORDER_TYPE_ONE_WAY ||
          order_type == ORDER_TYPE_EXPRESS_SINGLE
        ) {
          first_pickup_task = order.tasks[0];
        }
        if (
          order_type == ORDER_TYPE_BULK ||
          order_type == ORDER_TYPE_ONE_WAY ||
          order_type == ORDER_TYPE_EXPRESS_SINGLE
        ) {
          delivery_task = order.tasks[order.tasks?.length - 1];
        }
        if (
          order_type == ORDER_TYPE_ROUND ||
          order_type == ORDER_TYPE_EXPRESS_ROUND
        ) {
          let delivery_tasks = [];
          order.tasks.map((task) => {
            if (task.sequence == 2 && task?.type_id == TASK_TYPE_DELIVER) {
              delivery_tasks.push(task);
            }
          });
          delivery_task = delivery_tasks[delivery_tasks?.length - 1];
        }
      }

      //stepper conditions

      if (
        !(order_type == ORDER_TYPE_BULK && order.parent_id) &&
        order.pickup_tasks?.length > 0
      ) {
        is_pickup_task_started =
          order.pickup_tasks &&
          order.pickup_tasks[order.pickup_tasks?.length - 1].status ==
            TASK_STATUS_STARTED;
      }

      let round_delivery_tasks = [];
      let is_order_type_round =
        order_type == ORDER_TYPE_ROUND ||
        order_type == ORDER_TYPE_EXPRESS_ROUND;
      if (is_order_type_round) {
        round_delivery_tasks = order.deliver_tasks.filter(
          (task) => task.sequence == 2,
        );
      }

      let round_return_tasks = [];
      if (is_order_type_round && order.tasks?.length > 0) {
        round_return_tasks = order.deliver_tasks.filter(
          (task) => task.sequence == 3,
        );
        is_return_task_started =
          round_return_tasks[round_return_tasks?.length - 1].status ==
          TASK_STATUS_STARTED;
      }

      if (order.tasks) {
        is_any_task_started = order.tasks.some(
          (task) => task.status == TASK_STATUS_STARTED,
        );
      }

      if (order.tasks?.length > 0) {
        is_deliver_task_started =
          order.deliver_tasks &&
          ((!is_order_type_round &&
            order.deliver_tasks[order.deliver_tasks?.length - 1].status ==
              TASK_STATUS_STARTED) ||
            (is_order_type_round &&
              round_delivery_tasks[round_delivery_tasks?.length - 1].status ==
                TASK_STATUS_STARTED));
      }
    }

    //Order Confirmation status
    let order_confirmation_status = step_status_completed;
    if (is_order_pending) {
      order_confirmation_status = step_status_processing;
    } else if (is_order_declined) {
      order_confirmation_status = step_status_failed;
    }

    //Driver Picked Up status
    let driver_picked_up_status = step_status_pending;
    if (is_pickup_task_started) {
      driver_picked_up_status = step_status_processing;
    } else if (
      is_order_success ||
      order_type == ORDER_TYPE_ROUND_DELIVERY ||
      (order_type == ORDER_TYPE_BULK && order.status != ORDER_STATUS_PENDING) ||
      ((order_type == ORDER_TYPE_ONE_WAY ||
        order_type == ORDER_TYPE_ROUND ||
        order_type == ORDER_TYPE_EXPRESS_SINGLE ||
        order_type == ORDER_TYPE_EXPRESS_ROUND) &&
        first_pickup_task &&
        first_pickup_task.status == TASK_STATUS_COMPLETE)
    ) {
      driver_picked_up_status = step_status_completed;
    } else if (is_order_failure) {
      driver_picked_up_status = step_status_failed;
    }

    //Ready for self pickup status
    let order_ready_for_self_pickup = step_status_pending
    if(order.self_pickup){
      if(order.self_pickup_details.first_sms_sent){
        order_ready_for_self_pickup = step_status_completed
      }
    }

    //Driver on the Way status
    let driver_on_the_way_status = step_status_pending;
    if (is_deliver_task_started) {
      driver_on_the_way_status = step_status_processing;
    } else if (
      is_order_success ||
      ((order_type == ORDER_TYPE_ONE_WAY ||
        order_type == ORDER_TYPE_ROUND ||
        order_type == ORDER_TYPE_BULK ||
        order_type == ORDER_TYPE_EXPRESS_SINGLE ||
        order_type == ORDER_TYPE_EXPRESS_ROUND) &&
        delivery_task &&
        (delivery_task.status == TASK_STATUS_COMPLETE ||
          delivery_task.status == TASK_STATUS_STARTED))
    ) {
      driver_on_the_way_status = step_status_completed;
    } else if (is_order_failure) {
      driver_on_the_way_status = step_status_failed;
    }

    //Delivered status
    let delivered_status = step_status_pending;
    let is_order_type_round =
      order_type == ORDER_TYPE_ROUND || order_type == ORDER_TYPE_EXPRESS_ROUND;
    if (
      is_order_success ||
      (is_order_type_round &&
        delivery_task &&
        delivery_task.status == TASK_STATUS_COMPLETE)
    ) {
      delivered_status = step_status_completed;
    } else if (is_order_failure) {
      delivered_status = step_status_failed;
    }

    //Returned Status
    let returned_status = step_status_pending;
    if (is_order_success) {
      returned_status = step_status_completed;
    } else if (is_order_type_round && is_return_task_started) {
      returned_status = step_status_processing;
    } else if (is_order_failure) {
      returned_status = step_status_failed;
    }

    const is_rejected = order.deliver_tasks && order.deliver_tasks.some((task) => {
      return (task.sequence == 1 || task.sequence == 2) && task.is_rejected == true;
    });

    // Showing Delivery Message on certain conditions
    let show_delivery_message = false;

    if(order_confirmation_status == step_status_completed && driver_on_the_way_status ==  step_status_pending && !order.self_pickup) {
      show_delivery_message = true;
    }
    
    return (
      <div
        className="container-fluid page-dashboard"
        style={{ height: 'auto' }}
      >
        <QueueAnim type="bottom" className="ui-animate">
          {this.state.isLoading ? (
            <div style={{ height: '100%' }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <article className="article order-info">
                {windowWidth >= break_mobile_point ? (
                  <div className="row">
                    <div className="col-sm-6">
                      <h2 className="article-title">
                        Order #
                        <span style={{ color: '#e2aa01' }}>
                          {this.props.match.params.number}
                        </span>
                      </h2>
                    </div>
                    <div className="col-sm-6">
                      <h1 style={{ textAlign: 'right', margin: '0 0 5px 0' }}>
                        <a href="https://wakilni.com/">
                          <img
                            src="/assets/images/wakilni-logo-v2.png"
                            height="25"
                            alt={'Wakilni'}
                          />
                        </a>
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-6">
                      <h6>
                        Order #
                        <span style={{ color: '#e2aa01' }}>
                          {this.props.match.params.number}
                        </span>
                      </h6>
                    </div>
                    <div className="col-6">
                      <h1 style={{ textAlign: 'right', margin: '0 0 5px 0' }}>
                        <a href="https://wakilni.com/">
                          <img
                            src="/assets/images/wakilni-logo-v2.png"
                            height="15"
                            alt={'Wakilni'}
                          />
                        </a>
                      </h1>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div
                    className={
                      windowWidth >= break_mobile_point
                        ? 'col-md-5 child_div'
                        : 'col-md-12 child_div'
                    }
                  >
                    <div className="box box-default">
                      <div className="box-body">
                        <div className="row">
                          <div className={'col-md-12'}>
                            <Stepper
                              activeStep={stepIndex}
                              orientation={'vertical'}
                              sx={{margin: "10px"}}
                              connector={
                                <StepConnector
                                  sx={{
                                    '& .MuiStepConnector-line': {
                                      marginBottom: '5px',
                                    },
                                    '&:first-of-type .MuiStepConnector-line': {
                                      minHeight: '0px', // Custom height for the first connector
                                    },
                                  }}
                                />
                              }
                            >
                              <CustomizedStep
                                windowWidth={windowWidth}
                                status={order_confirmation_status}
                                step_label={'Order Confirmation'}
                                step_details={
                                  <ConfirmedDetails order={order} show_delivery_message={show_delivery_message} />
                                }
                              />
                              {
                                !order.self_pickup ?
                                  <CustomizedStep
                                    windowWidth={windowWidth}
                                    status={driver_picked_up_status}
                                    step_label={'Driver Picked Up'}
                                    step_details={<PickedUpDetails order={order} />}
                                    order_status_code={order.status_code}
                                  />
                                : <div></div> 
                              }
                              <CustomizedStep
                                windowWidth={windowWidth}
                                status={
                                  order.self_pickup ? 
                                  order_ready_for_self_pickup
                                  : driver_on_the_way_status
                                }
                                step_label={ 
                                  order.self_pickup ? 
                                  'Order Ready For Self-Pickup'
                                  : 'Driver On The Way'
                                }
                                step_details={<OnTheWayDetails order={order} />}
                                order_status_code={order.status_code}
                              />

                              <CustomizedStep
                                windowWidth={windowWidth}
                                status={delivered_status}
                                step_label={order.self_pickup ? 'Order Picked Up' : is_rejected? 'Delivered, Rejected': 'Delivered'}
                                step_details={
                                  <DeliveredDetails order={order} />
                                }
                              />

                              {(order_type == ORDER_TYPE_ROUND ||
                                order_type == ORDER_TYPE_EXPRESS_ROUND) &&
                              (order.orderDetails.customer?.type ==
                                CUSTOMER_TYPE_CUSTOMER ||
                                order.orderDetails.customer?.type ==
                                  CUSTOMER_TYPE_OFFLINE_BUSINESS) ? (
                                <CustomizedStep
                                  windowWidth={windowWidth}
                                  status={returned_status}
                                  step_label={'Returned'}
                                  step_details={
                                    <ReturnedDetails
                                      order={order}
                                      order_status_code={order.status_code}
                                    />
                                  }
                                />
                              ) : (
                                <span />
                              )}
                            </Stepper>
                            <br />
                            <p style={{ color: 'red' }}>
                              {' '}
                              If you want to change your delivery preferences,
                              WhatsApp us on{' '}
                              <b>
                                <a
                                  target="_blank"
                                  href={`https://wa.me/96176766115?text=${encodeURIComponent(
                                    sms_msg,
                                  )}`}
                                  style={{ color: 'red' }}
                                >
                                  {process.env.REACT_APP_WAKILNI_CUSTOMER_SUCCESS_NUMBER || "76-766115"}
                                </a>
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      windowWidth >= break_mobile_point
                        ? 'col-md-7 child_div'
                        : 'col-md-12 child_div'
                    }
                  >
                    <div
                      className="box box-default"
                      style={{ minHeight: '400px' }}
                    >
                      <div className="box-body h-100">
                        <div className="row h-100">
                          {this.state.show_rating &&
                            order.status === ORDER_STATUS_SUCCESS && (
                              <RateComponent
                                classes={classes}
                                order={order}
                                dispatch={this.props.dispatch}
                              />
                            )}
                          {(order.status == ORDER_STATUS_PROCESSING ||
                            is_any_task_started) && (
                            <TrackingMap order={order} coordinates={this.state.updated_coordinate} />
                          )}
                          {!this.state.show_rating &&
                            !(
                              order.status === ORDER_STATUS_PROCESSING ||
                              order.status === ORDER_STATUS_FAILED ||
                              order.status === ORDER_STATUS_PENDING_CANCELLATION
                            ) && (
                              <div className="col-md-12">
                                <div
                                  className="row"
                                  style={{ marginTop: '10%' }}
                                >
                                  <div className="col-md-12">
                                    <h4 style={{ textAlign: 'center' }}>
                                      <span
                                        className="material-icons"
                                        style={{
                                          color: 'rgb(226, 170, 1)',
                                          fontSize: '70px',
                                        }}
                                      >
                                        favorite_border
                                      </span>
                                    </h4>
                                    <h4 style={{ textAlign: 'center' }}>
                                      Thank you for using our services
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          )}
        </QueueAnim>
        {add_review && <AddReview/>}
      </div>
    );
  }
}

TrackingOrder.propTypes = {
  order: PropTypes.object,
  add_review: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    order: state.orders.order,
    add_review: state.modals.add_review,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(TrackingOrder));
